@import "~sass/common/mixins/layout";

$zero: 0;
$one: 1;

.ot_ci_slide {
  @include transition();
  max-height: 0px; // closed byDefault
  overflow: hidden;
}

.ot_ci_slide.ot_ci_open {
    max-height: 10000vh;
    overflow: hidden;
}


/*  Bounce */
@keyframes bounce {
	0%   { transform: translateY(0%) scaleY(0.6); }
	60%  { transform: translateY(-100%) scaleY(1.1); }
	70%  { transform: translateY(0%) scaleY(0.95) scaleX(1.05); }
	80%  { transform: translateY(0%) scaleY(1.05) scaleX(1); }
	90%  { transform: translateY(0%) scaleY(0.95) scaleX(1); }
	100% { transform: translateY(0%) scaleY(1) scaleX(1); }
}
@-webkit-keyframes bounce {
	0%   {  -webkit-transform: translateY(0%) scaleY(0.6); }
	60%  {  -webkit-transform: translateY(-100%) scaleY(1.1); }
	70%  {  -webkit-transform: translateY(0%) scaleY(0.95) scaleX(1.05); }
	80%  {  -webkit-transform: translateY(0%) scaleY(1.05) scaleX(1); }
	90%  {  -webkit-transform: translateY(0%) scaleY(0.95) scaleX(1); }
	100% {  -webkit-transform: translateY(0%) scaleY(1) scaleX(1); }
}
@mixin bounce() {
  @include animationTiming();
  animation-name: bounce;
	-webkit-animation-name: bounce;
  animation-iteration-count: 1;
	-webkit-animation-iteration-count: 1;
	animation-duration: 1.6s;
	-webkit-animation-duration: 1.6s;
	transform-origin: 50% 100%;
	-ms-transform-origin: 50% 100%;
	-webkit-transform-origin: 50% 100%;
}
.ot_ci_bounce {
	@include bounce();
}

/*  FadeIn */
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
  	100% {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    0% {opacity: 0;}
  	100% {opacity: 1;}
  }
  @mixin fadeIn($duration: 0.3s, $delay: 0s) {
    @include animationTiming();
    animation-name: fadeIn;
    -webkit-animation-name: fadeIn;
    animation-duration: $duration;
    -webkit-animation-duration: $duration;
    animation-delay: $delay;
    -webkit-animation-delay: $delay;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
  }
  .anime_fadeIn {
    @include fadeIn();
  }

/*  FadeIn With Delay*/
@-webkit-keyframes fadeIn_withDelay {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn_withDelay {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
@mixin fadeIn_withDelay($duration: 0.5s, $delay: 0.3s) {
  @include animationTiming();
  animation-name: fadeIn_withDelay;
  -webkit-animation-name: fadeIn_withDelay;
  animation-duration: $duration;
  -webkit-animation-duration: $duration;
  animation-delay: $delay;
  -webkit-animation-delay: $delay;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}
.anime_fadeIn_withDelay {
  @include fadeIn_withDelay();
}

/*  FadeOut */
  @-webkit-keyframes fadeOut {
    0% {opacity: 1;}
  	100% {opacity: 0;}
  }
  @keyframes fadeOut {
    0% {opacity: 1;}
  	100% {opacity: 0;}
  }
  @mixin fadeOut($duration: 0.3s) {
    @include animationTiming();
    overflow: hidden;
    animation-name: fadeOut;
    -webkit-animation-name: fadeOut;
    animation-duration: $duration;
    -webkit-animation-duration: $duration;
    animation-delay: 1.5s;
    -webkit-animation-delay: 1.5s;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
  }
  .anime_fadeOut {
    @include fadeOut();
  }

  /* FadeOut No Delay */
  @-webkit-keyframes fadeOut_noDelay {
    0% {opacity: 1;}
  	100% {opacity: 0;}
  }
  @keyframes fadeOut_noDelay {
    0% {opacity: 1;}
  	100% {opacity: 0;}
  }
  @mixin fadeOut_noDelay($duration: 0.4s, $delay: 0s) {
    @include animationTiming();
    overflow: hidden;
    display:none;
    animation-name: fadeOut_noDelay;
    -webkit-animation-name: fadeOut_noDelay;
    animation-duration: $duration;
    -webkit-animation-duration: $duration;
    animation-delay: $delay;
    -webkit-animation-delay: $delay;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
  }
  .anime_fadeOut_noDelay {
    @include fadeOut_noDelay();
  }

/* Pulse */
  @mixin pulse($name, $max-opacity: 1, $min-opacity:0) {
    @keyframes #{$name} {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }

  @include pulse('pulse-in-out');
  .pulse-in-out {
    animation: pulse-in-out 2s infinite;
  }

/*  Floating */
  @keyframes floating {
  	0%   { transform: translateY(0%); }
  	50%  { transform: translateY(5px); }
  	100% { transform: translateY(0%); }
  }
  @-webkit-keyframes floating {
  	0%   { -webkit-transform: translateY(0%);	}
  	50%  { -webkit-transform: translateY(5px);	}
  	100% { -webkit-transform: translateY(0%);}
  }
  @mixin floating($count: infinite)  {
    @include animationTiming();
    animation-name: floating;
  	-webkit-animation-name: floating;
  	animation-duration: 1.6s;
  	-webkit-animation-duration: 1.6s;
  	animation-iteration-count: $count;
  	-webkit-animation-iteration-count: $count;
  }
  .ot_ci_floating {
  	@include floating();
  }
  .ot_ci_floating3 {
  	@include floating(3);
  }

/*  Grow Y */
@-webkit-keyframes growY {
  0% {
    max-height: 0px;
    opacity: 0;
  }
	100% {
    max-height: 2000px;
    opacity: 1;
  }
}
@keyframes growY {
  0% {
    max-height: 0px;
    opacity: 0;
  }
	100% {
    max-height: 2000px;
    opacity: 1;
  }
}
@mixin growY($duration: 0.5s) {
  animation-name: growY;
  -webkit-animation-name: growY;

  animation-duration: $duration;
  -webkit-animation-duration: $duration;

  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
}
.anime_growY {
  overflow: hidden;
  max-height: 2000px;
  // @include growY();
}

/*  Slide Down */
@-webkit-keyframes slideDown {
  0% {
    opacity: 0;
		transform: scaleY(0.1);
	}
	40% {
		transform: scaleY(1.02);
	}
	60% {
		transform: scaleY(0.98);
	}
	80% {
		transform: scaleY(1.01);
	}
	100% {
		transform: scaleY(0.98);
	}
	80% {
		transform: scaleY(1.01);
	}
	100% {
		transform: scaleY(1);
    opacity: 1;
	}
}
@keyframes slideDown {
  0% {
		transform: scaleY(0.1);
	}
	40% {
		transform: scaleY(1.02);
	}
	60% {
		transform: scaleY(0.98);
	}
	80% {
		transform: scaleY(1.01);
	}
	100% {
		transform: scaleY(0.98);
	}
	80% {
		transform: scaleY(1.01);
	}
	100% {
		transform: scaleY(1);
	}
}
@mixin slideDown($duration: 0.5s) {
  overflow: hidden;

  animation-name: slideDown;
  -webkit-animation-name: slideDown;

  animation-duration: $duration;
  -webkit-animation-duration: $duration;

  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;

	transform-origin: 50% 0%;
	-ms-transform-origin: 50% 0%;
	-webkit-transform-origin: 50% 0%;
}
.anime_slideDown {
  @include slideDown();
}

/* Shake animation */
@-webkit-keyframes shake_x {
    0%, 100% {-webkit-transform: translateX(0);}
    10%, 30%, 50%, 70%, 90% {-webkit-transform: translateX(6px);}
    20%, 40%, 60%, 80% {-webkit-transform: translateX(-6px);}
}
@keyframes shake_x {
    0%, 100% {transform: translateX(0);}
    10%, 30%, 50%, 70%, 90% {transform: translateX(6px);}
    20%, 40%, 60%, 80% {transform: translateX(-6px);}
}
@mixin shake_x($duration: 0.5s) {
  animation-name: shake_x;
  -webkit-animation-name: shake_x;

  animation-duration: $duration;
  -webkit-animation-duration: $duration;

  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
}
.anime_shake_x {
  @include shake_x();
}

/*  Drop */
@-webkit-keyframes drop {
  0% {
    opacity: 0;
		transform: translateY(-10px);
	}
	100% {
    opacity: 1;
		transform: translateY(0px);
	}
}
@keyframes drop {
  0% {
    opacity: 0;
		transform: translateY(-10px);
	}
	100% {
    opacity: 1;
		transform: translateY(0px);
	}
}
@mixin drop($duration: 0.5s) {
  overflow: hidden;

  animation-name: drop;
  -webkit-animation-name: drop;

  animation-duration: $duration;
  -webkit-animation-duration: $duration;

  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;

	transform-origin: 50% 0%;
	-ms-transform-origin: 50% 0%;
	-webkit-transform-origin: 50% 0%;
}
.anime_drop {
  @include drop();
}

@-webkit-keyframes slideLeft {
  0% {
    transform: translateX(100vh);
    opacity: 0.75;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes slideLeft {
  0% {
    transform: translateX(100vh);
    opacity: 0.75;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
@mixin slideLeft($duration: 0.5s) {
  overflow: hidden;

  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;

  animation-duration: $duration;
  -webkit-animation-duration: $duration;

  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;

  transform-origin: 50% 0%;
  -ms-transform-origin: 50% 0%;
  -webkit-transform-origin: 50% 0%;
}
.anime_slideLeft {
  @include slideLeft();
}



@-webkit-keyframes expandX {
  0% {
    transform: translateX(-5px);
    opacity: 0;
    max-width: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
    max-width: none;
  }
}



