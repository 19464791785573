@import "~sass/common/mixins/layout";
@import "~sass/fonts";
@import '~sass/colors';
@import "~sass/media";
@import "~sass/variables";
@import "~sass/placeholders";

.ot_callout {
  @extend %ot_box_shadow;
  position: relative;
	border-radius: 3px;
  margin-bottom: $base-padding;

  // mobile and tablet portrait
  @media (max-width: $screen-sm-max) {
    padding: 20px;
  }
  // tablet landscape and up
  @media (min-width: $screen-md-min) {
    padding: 30px;
  }
}
.ot_callout h4 {
  color: $gray-mid-dark;
  margin-bottom: 30px;
}
.ot_callout label {
  @extend .ot_ci_subtitleSm;
  color: $gray-mid-dark;
  margin-bottom: 10px;
}
.ot_callout form {
  margin: 0px;
  ul {
    padding-left: 0px;
    list-style-type: none;
  }
  li {
    margin-bottom: 30px;
  }
}
