@import "~sass/media";

// box shadow
%ot_box_shadow {
  box-shadow: 0 2px 8px 0 rgba(136,136,136,0.5);
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  &:hover, &:focus, &.active {
    box-shadow: 0 2px 8px 0 rgba(102,102,102, 0.5);
    transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  }
}

%ot_box_shadow_mobile {
  // mobile
  @media (max-width: $mobile-max) {
    box-shadow: 0 2px 8px 0 rgba(136,136,136,0.5);
    transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    &:hover, &:focus, &.active {
      box-shadow: 0 2px 8px 0 rgba(102,102,102, 0.5);
      transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
}

%ot_box_shadow_tablet {
  // desktop
  @media (min-width: $tablet-min)
    and (max-width: $tablet-max)  {
    box-shadow: 0 2px 8px 0 rgba(136,136,136,0.5);
    transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    &:hover, &:focus, &.active {
      box-shadow: 0 2px 8px 0 rgba(102,102,102, 0.5);
      transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
}

%ot_box_shadow_desktop {
  // desktop
  @media (min-width: $desktop-min)  {
    box-shadow: 0 2px 8px 0 rgba(136,136,136,0.5);
    transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    &:hover, &:focus, &.active {
      box-shadow: 0 2px 8px 0 rgba(102,102,102, 0.5);
      transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
}

%ot_bgd_blur {
  backdrop-filter: blur(2px);
}