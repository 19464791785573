.ot_landscapeMessageScreen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;
  }
  
  .ot_landscapeMessageBox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    overflow: hidden;
  }
  
  .ot_landscapeMessage {
    font-weight: 700;
    font-size: 18px;
    color: #111013;
    text-align: center;
    overflow: hidden;
  }