@import "~sass/colors";
$text-color:#ccc;
$disabled-color: #888;
$inactive-opacity: .3;

.ot_seatingChart {
  position: relative;
  cursor: default;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    overflow: hidden;
  }
  .chart_svg {
    touch-action: pan-y;
    pointer-events: auto;
    background: #fff;
    @media all and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
      overflow: hidden;
    }
    svg svg {
      overflow: visible;
    }
    svg {
      user-select: none;
      overflow: visible;
    }
    text.verticalCenter {
      dominant-baseline: central;
    }
    .animate {
      transition: all .5s ease-in-out;
    }

    .section.clickable {
      cursor: pointer;
      .section_area {
        transition: fill 0.3s ese-in-out;
      }
    }

    .section.mobile_not_clickable{
	  pointer-events:none;
    }

    .section.clickable:hover {
      .section_area {
        fill: darken($admin-blue, 0.3%);
      }
    }

    .canMove {
      cursor: move;
      touch-action: none;
    }
    .filteredOut {
      opacity: .3;
      pointer-events: none;
    }
    .label {
      text {
        font-weight: bold;
      }
    }
  }
  .chart_group {
    .animate{
      svg{
        overflow: visible;
        svg{
          overflow: visible;
        }
      }
    }
  }
}