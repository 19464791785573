// GLOBAL
$black: #000; //(0,0,0)
$white: #fff; //(255,255,255)
$off-white: #fcfcfc;
$gray-light: #eee; // (238,238,238)
$gray-rule: #ddd; //(221,221,221)
$gray-mid-light: #D6DDE7; //(214,221,231)
$gray: #888; //(136,136,136)
$gray-mid-dark: #666; //(102,102,102)
$gray-dark: #3b454a; //#222 (34,34,34)
$smoke-white: #F9FAFB;



// UTILITY
$ot-blue:  #008cff; //(0,140,255)
$admin-blue:  #00B1F9;
$light-blue:  #e0f2ff; //(224,242,255)
$dark-blue: #0670C7;
$red: #990000; //(153,0,0)
$green: #76d164; //(49,178,28)
$yellow: #fdbc34; //(245,166,35)


