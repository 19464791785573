@import "~sass/colors";

// app colors...
$font-color: $gray-dark;
$primary-color: $ot-blue;
$primary-dk-color: $dark-blue;
$disabled-color: $gray-mid-light;
$success-color: $green;
$error-color: $red;
$gray-rule: #ddd;

//buttons
$button-active-opacity: .3;
$btn-disabled-opacity:.3;
$btn-primary-color: $white;
$button-color: $ot-blue !default;
$btn-link-disabled-color: fade-out($button-color, .5);
$btn-primary-bg: $button-color;
$btn-primary-border: darken($button-color, 10%);
$button-hover-color: darken($button-color, 20%);
$button-active-color: $button-hover-color;

//spacing
$border-radius: 3px;
$min-padding: 10px;
$base-padding:  20px;
$header-padding: 30px;
$padding-mobile: 2.5vh;
$padding-xs-vertical:       1px !default;
$padding-xs-horizontal:     5px !default;


// z-index
$nav-z-index: 500;
$z-alert: 50000;
$z-modal: 20000;
$z-overlay: 1000;
$z-max: 9999;
$z-base: 1;
$z-over: 10;

//navbar
$navbar-inverse-bg: $gray-dark !default;
$navbar-inverse-color: $white;
$navbar-inverse-link-color: $navbar-inverse-color !default;
$navbar-height-min: 50;
$navbar-height-min: 100;
$navbar-desktop: 64;



//Footer
$footer-background: $navbar-inverse-bg !default;
$footer-color: $navbar-inverse-color !default;

// layout
$container-width: 1080px;
$font-family: 'Poppins', Helvetica, sans-serif;
