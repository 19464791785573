@import "~sass/media";

.ot_mapControllers {
  position: absolute;
  right: 0;
  z-index: 10;
  display: inline-flex;
  width: auto;
  // small devices
  @media (max-width: $tablet-max) {
    bottom: 100px;
    height: 120px;
  }
  // desktop
  @media (min-width: $desktop-min) {
    top: 0;
    right: 0;
    padding: 20px;
    height: 160px;
  }
}

.ot_mapControl{
  position: absolute;
  right: 0;
  z-index: 10;
  width: auto !important;
  padding: 20px;
  //mobile
  @media (max-width: $screen-xs-max) {
    position: fixed;
  }
  transition: all 0.3s ease-in-out;
  align-items: flex-end !important;
}

.ot_contextualMap{
  position: relative;
  width: 250px;
  height: 148px;
  margin-right: 8px;
  border: 1px solid #E9ECEF;;
  background-color: #FFF;
  border-radius: 3px;
  opacity: 0.9;
  overflow: hidden;
  svg{
    width: 250px;
    height: 148px;
    .ot_seatingChartObject{
      rect, g, path, circle{
        fill: #E9ECEF;
        stroke: #E9ECEF;
      }
    }
    .available{
      rect, g, path, circle{
        fill: #D4E4F7;
      }
    }
  }
  text{
    display: none;
  }
  .focusSquare{
    border: solid 1px #071459;
    position: absolute;
    transform: translate(-50%, -50%);
  }
  &:after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

#miniMap{
  #viewportGroup{
    // transform: scale(0.2) !important;
    // height: 148px !important;
  }
}