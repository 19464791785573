$gray-mid-light: #888;
@import "~sass/colors";

.ot_seat {
  fill:rgba(0,0,0,0);
  transition: all .5s ease-in-out;
  .userIcon {
    stroke: $gray-mid-light;
    fill:$gray-mid-light;
  }
  &.inCart {
    cursor: default !important;
  }
  &.killSeat {
  //  opacity: 0 !important;
    line {
      stroke: $gray-mid-light !important;
    }
  }
  text {
    fill:white;
    stroke: none;
    stroke-width:0;
  }

  &.selected:not(.editing) {
    text {
      fill:white !important; //importatnt to override default priceLevel color
      stroke: white !important;
    }
  }
  .seatNumber {
    opacity:0;
    text-anchor: middle;
    dominant-baseline: central;
    font-size: 7.5;
    stroke-width: 0;
    font-weight:bold;
    transition: all .5s ease-in-out;
    letter-spacing: 1px;
  }
  &.editing {
    cursor: pointer;
    .seatNumber {
      opacity:1;
      f
      &.transparent {
        opacity:0
      }
    }
    stroke:black;
  }
  &.selectable {
    cursor: pointer;
    .seatNumber {
      opacity:1;
      &.transparent {
        opacity:0
      }
    }
  }
  &.unselectable {
    cursor: not-allowed;
    opacity:.3;
    text {
      fill: $gray-mid-light;
    }
    circle {
      stroke: $gray !important;
      fill: $gray !important;
    }
  }
  text{
    -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none;
          cursor: inherit;
  }
}
