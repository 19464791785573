@import "~sass/variables";
@import "~sass/common/mixins/layout";


.ot_ci_loadingOverlay {
  position: relative;
  text-align:center;
  width: 100%;
  opacity: 0.8;
  display: block;
  position: fixed;
  z-index: 5000;
  top: 0;
  left: 0;
  @include transition();
  p {
    margin-top: 20px;
  }
}

.ot_ci_loadingSpinner {
  display: flex;
  align-items: center
}

.ot_loadingIcon_sm {
  height: 75px;
  overflow: visible;
}
.ot_loadingIcon_lg {
  height:100px;
  overflow: visible;
}
.ot_loadingIcon_2x {
  height:150px;
  overflow: visible;
}
.ot_loadingIcon_3x {
  height:200px;
  overflow: visible;
}
.ot_loadingIcon_4x {
  height:250px;
  overflow: visible;
}
.ot_loadingIcon_5x {
  height:300px;
  overflow: visible;
}

.ot_loadingGif {
  display: block;
  width: 50px;
  height: 50px;
  background: url('./../../../images/icons/ot_ovationtix_loading_transparent.gif') no-repeat center;
  -webkit-background-size: cover;
  background-size: cover;

}


@-webkit-keyframes iconBounce {
  0% {
    -webkit-transform: translateY(0%);
  }
  50%{
    -webkit-transform: translateY(-10%);
  }
  100%{
    -webkit-transform: translateY(0%);
  }
}
@-moz-keyframes iconBounce {
  0% {
    -moz-transform: translateY(0%);
  }
  50%{
    -moz-transform: translateY(-10%);
  }
  100%{
    -moz-transform: translateY(0%);
  }
}
@-ms-keyframes iconBounce {
  0% {
    -ms-transform: translateY(0%);
  }
  50%{
    -ms-transform: translateY(-10%);
  }
  100%{
    -ms-transform: translateY(0%);
  }
}
@-o-keyframes iconBounce {
  0% {
    -o-transform: translateY(0%);
  }
  50%{
    -o-transform: translateY(-10%);
  }
  100%{
    -o-transform: translateY(0%);
  }
}
@keyframes iconBounce {
  0% {
    transform: translateY(0%);
  }
  50%{
    transform: translateY(-10%);
  }
  100%{
    transform: translateY(0%);
  }
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform:  rotateY(0deg)
  }
  50% {
    -webkit-transform: rotateY(180deg)
  }
  100% {
    -webkit-transform: rotateY(360deg)
  }
}
@-moz-keyframes rotate {
  0% {
    -moz-transform:  rotateY(0deg)
  }
  50% {
    -moz-transform: rotateY(180deg)
  }
  100% {
    -moz-transform: rotateY(360deg)
  }
}
@-ms-keyframes rotate {
  0% {
    -ms-transform:  rotateY(0deg)
  }
  50% {
    -ms-transform: rotateY(180deg)
  }
  100% {
    -ms-transform: rotateY(360deg)
  }
}
@-o-keyframes rotate {
  0% {
    -o-transform:  rotateY(0deg)
  }
  50% {
    -o-transform: rotateY(180deg)
  }
  100% {
    -o-transform: rotateY(360deg)
  }
}
@keyframes rotate {
  0% {
    transform:  rotateY(0deg)
  }
  50% {
    transform: rotateY(180deg)
  }
  100% {
    transform: rotateY(360deg)
  }
}

@-webkit-keyframes dance {
  100% {
    stroke-dashoffset: 800;
  }
  0% {
    stroke-dashoffset: 0;
  }
}
@-moz-keyframes dance {
  100% {
    stroke-dashoffset: 800;
  }
  0% {
    stroke-dashoffset: 0;
  }
}
@-ms-keyframes dance {
  100% {
    stroke-dashoffset: 800;
  }
  0% {
    stroke-dashoffset: 0;
  }
}
@-o-keyframes dance {
  100% {
    stroke-dashoffset: 800;
  }
  0% {
    stroke-dashoffset: 0;
  }
}
@keyframes dance {
  100% {
    stroke-dashoffset: 800;
  }
  0% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes inner {
  100% {
    stroke-dashoffset: 400;
  }
  0% {
    stroke-dashoffset: 0;
  }
}
@-moz-keyframes inner {
  100% {
    stroke-dashoffset: 400;
  }
  0% {
    stroke-dashoffset: 0;
  }
}
@-ms-keyframes inner {
  100% {
    stroke-dashoffset: 400;
  }
  0% {
    stroke-dashoffset: 0;
  }
}
@-o-keyframes inner {
  100% {
    stroke-dashoffset: 400;
  }
  0% {
    stroke-dashoffset: 0;
  }
}
@keyframes inner {
  100% {
    stroke-dashoffset: 400;
  }
  0% {
    stroke-dashoffset: 0;
  }
}

.st0 {
  fill:none;
  stroke:#CCCCCC;
  stroke-width:6;
  stroke-miterlimit:10;
  stroke-linecap: round;
}
.st1 {
  fill:none;
  stroke:#222222;
  stroke-width:6;
  stroke-miterlimit:10;
  stroke-linecap: round;
}
.outer {
  stroke-dasharray: 400;
  stroke-dashoffset: 400;
  -webkit-animation: dance 2s linear infinite reverse;
  -moz-animation: dance 2s linear infinite reverse;
  -o-animation: dance 2s linear infinite reverse;
  animation: dance 2s linear infinite reverse;
}
.inner {
  stroke-dasharray: 200;
  stroke-dashoffset: 200;
  -webkit-animation: inner 2s reverse linear infinite;
  -moz-animation: inner 2s reverse linear infinite;
  -ms-animation: inner 2s reverse linear infinite;
  -o-animation: inner 2s reverse linear infinite;
  animation: inner 2s reverse linear infinite;
}
.canvas {
  -webkit-transform: translateX(10px);
  -moz-transform: translateX(10px);
  -ms-transform: translateX(10px);
  -o-transform: translateX(10px);
  transform: translateX(10px);
}
.image {
  -webkit-transform-origin: 50% 0%;
  -moz-transform-origin: 50% 0%;
  -ms-transform-origin: 50% 0%;
  -o-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-animation: iconBounce 2s ease-in-out infinite;
  -moz-animation: iconBounce 2s ease-in-out infinite;
  -ms-animation: iconBounce 2s ease-in-out infinite;
  -o-animation: iconBounce 2s ease-in-out infinite;
  animation: iconBounce 2s ease-in-out infinite;
}
