@import '~sass/colors';
@import "~sass/variables";
@import "~sass/media";
@import '~sass/common/mixins/layout';
@import '~sass/common/styles/animations';
@import "~sass/fonts";
@import url('https://fonts.googleapis.com/css?family=Inter:400,600,700&display=swap');


// ICON BUTTON

.ot_iconButton {
  border: none;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.ot_iconPromoButton {
  border: none;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  font-family: Inter !important;
  background-color: rgb(217, 217, 217) !important;
}
.btn.ot_iconPromoButton.disabled {
  border: none;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  font-family: Inter !important;
  background-color: rgb(217, 217, 217) !important;
  color : rgb(217, 217, 217) !important;
  opacity: 0.6;
}
.ot_iconButton.disabled {
  border-color: transparent !important;
}
.ot_iconButton,
.ot_iconButton-bounded {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: auto;
  border: none;
  color: $gray-mid-dark;
  background-color: transparent;
  font-size: 14px;
  //.ot_svg_fill  {
  //  fill: $gray-mid-dark;
  //}
  &:link, &:visited, &:hover, &:focus, &:active {
    outline: none;
    color: $gray-mid-dark;
    //.ot_svg_fill {
    //  fill: $gray-mid-dark;
    //}
  }
  &:disabled, &[disabled],
  &.disabled {
    color: $gray-mid-light !important;
    cursor: none;
    .ot_svg_fill {
      fill: $gray-mid-light;
    }
  }
}



.ot_iconButton.icon-top,
.ot_iconButton-bounded.icon-top {
  flex-direction: column-reverse;
}

.ot_iconButton.icon-bottom,
.ot_iconButton-bounded.icon-bottom {
  flex-direction: column;
}

.ot_iconButton.icon-left,
.ot_iconButton-bounded.icon-left {
  flex-direction: row-reverse;
}

.ot_iconButton.icon-right.text-left,
.ot_iconButton-bounded.icon-right.text-left {
  justify-content: flex-start;
}

.ot_iconButton.icon-left.text-left,
.ot_iconButton-bounded.icon-left.text-left {
  justify-content: flex-end;
}

.ot_iconButton  > *,
.ot_iconButton-bounded  > * {
  margin: 0px;
  pointer-events: none;
}

// labels
.ot_iconButton.icon-top .label,
.ot_iconButton-bounded.icon-top .label {
  margin: 5px 0px 0px;
}

.ot_iconButton.icon-right .label,
.ot_iconButton-bounded.icon-right .label {
  margin: 0px 10px 0px 0px;
}

.ot_iconButton.icon-bottom .label,
.ot_iconButton-bounded.icon-bottom .label {
  margin: 0px 0px 5px;
}

.ot_iconButton.icon-left .label,
.ot_iconButton-bounded.icon-left .label {
  margin: 0px 0px 0px 10px;
}

.ot_iconButton.conditionalIcon.text-left .label,
.ot_iconButton-bounded.conditionalIcon.text-left .label {
  margin-right: 5px;
}


.showIcon-false,  .showIcon-true {
  border-radius: 50%;
}

.showIcon-true svg {
  opacity: 1;
  transition: all 0.2s ease-out;
}

.showIcon-null svg,
.showIcon-undefined svg,
.showIcon-false svg {
  opacity: 0;
  transition: all 0.2s ease-out;
}

.ot_iconButton.ot_downButton,
.ot_iconButton.ot_bottomButton {
  border: none;
  -webkit-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}
.ot_iconButton.ot_downButton.isActive,
.ot_iconButton.ot_downButton.isUp {
  transform: rotateX(180deg);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.ot_iconButton.ot_topButton {
  border: none;
}
.ot_iconButton.ot_prevButton,
.ot_iconButton.ot_leftButton {
  border: none;
  width: 20px !important;
  height: 20px !important;
}
.ot_iconButton.ot_nextButton,
.ot_iconButton.ot_rightButton {
  border: none;
  width: 20px !important;
  height: 20px !important;
}

/* Cart Button */
.ot_ci_cartButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px !important;
  min-width: 65px;
  width: 100%;
  height: 100%;
  .badge-container {
    position: relative;
    left: -3px;
    height: 22px;
    overflow: hidden;
    transition: width 0.5s ease-in-out opacity 0.5s ease-in-out;
    &.open {
      opacity: 1;
    }
    &.close {
      width: 0px;
      opacity: 0;
    }
  }
}

.ot_ci_countBadge {
  display: flex;
  justify-content: center;
  align-items: center;
  @include icon-circle(20px);

  line-height: 1em;
  font-size: 10px !important;
  font-weight: 600;
  flex: 0 0 20px !important;
  @include transition();
}

.ot_helpButton {
  margin-left: 5px;
  transform: translateY(-3px);
}

.ot_iconButton.ot_promoButton {
  flex-direction: row;
  justify-content: flex-start !important;
  width: 100%;
  > span {
    order: 2;
  }
  > div {
    order: 1;
  }
  > svg {
    order: 3;
    margin-left: auto;
  }
}
.ot_squareButton{
  height: 80px;
  width: 80px;
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 0 13px 0 rgba(204,204,204,0.5);
  .active{
    border-color: #00B1F9;
  }
}