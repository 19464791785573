@import "~sass/placeholders";


.ot_zoomButtons {
  transition: all .25s ease-in-out;
}

.ot_zoomButtons.ot_ci_zoomToolbar {
  width: 40px;
  // height: 80px;
  padding: 0px;
  transition: all .25s ease-in-out;
  background-color: #fff;
  border-radius: 3px;
  @extend %ot_box_shadow;

  .container {
    width: 40px;
    height: 80px;

  }

  button  {
    width: 40px;
    height: 40px;
  }
}

.zoomButtonDivider{
 height: 1px;
 width: 32px; 
 background: #E9ECEF;
 margin: 0 auto;
}