@import '~sass/colors';
@import "~sass/media";
@import "~sass/variables";
@import "~sass/common/mixins/layout";
@import "~sass/fonts";
@import url('https://fonts.googleapis.com/css?family=Inter:400,600,700&display=swap');

button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  display: block;
  width: 100%;
  padding: 0px;
  margin: 0px;
  border: none;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-color: transparent;
  background-repeat: none;
  background-image: none;
  text-decoration: none;
  box-shadow: none;
  white-space: nowrap;
  user-select: none;
  transition: all 0.3s ease-in-out;
  > span {
    display: inline-block;
    margin: 0px auto;
    cursor: pointer;
  }
  > * {
    pointer-events: none;
  }
  &:hover, &:focus, &:active {
    outline: none;
    box-shadow: none;
    transition: all 0.3s ease-in-out;
    outline: none;
  }
  &.disabled, &:disabled {
    cursor: not-allowed !important;
    opacity: $btn-disabled-opacity;
    // IE8 filter
    $opacity-ie: ($btn-disabled-opacity * 100);
    filter: alpha(opacity=$opacity-ie);
    box-shadow:none;
  }
}

%button_font_size {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.6em;
  //letter-spacing: 0.05em;
}

%button_layout  {
  height: 40px;
  padding: 6px;
  border: 1px solid transparent;
  border-radius: $border-radius;
  line-height: 1.6em;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

%button_custom {
  display: flex;
  justify-content: center;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
  //-webkit-box-pack: center;
  //-moz-box-pack: center;
}

.ot_buttonLink {
  text-decoration: none;
}

.ot_submitButton{
  @extend %button_font_size;
  @extend %button_layout;
  background-color: $green !important;
  color: $white !important;
  font-weight: 500;
}

// BUTTON SIZES
  .ot_btn-large {
    // mobile
    @media (max-width: $screen-xs-max) {
      width: 100% !important;
      text-align: center;
    }
    // tablet  and desktop
    @media (min-width: $screen-sm-min) {
       width: 300px !important;
    }
  }

.ot_btn-medium {
    // mobile and tablet portrait
    @media (max-width: $screen-sm-max) {
      min-width: 175px;
      max-width: 200px;
    }
    max-width: 200px;
  }

.ot_btn-small { //only affects the text
    letter-spacing: normal;
    flex-grow: 0;
    // mobile and tablet portrait
    @media (max-width: $screen-sm-max) {
      font-size: 12px;
      letter-spacing: normal;
      padding: 6px 4px;
      @media (min-width: $screen-sm-min) {
        padding: 6px;
      }
    }
    // tablet landscape and desktop
    @media (min-width: $screen-md-min) {
      width: 125px;
      font-size: 14px;
      padding-left: 6px;
      padding-right: 6px;
    }
  }
//


// DEFAULT BUTTON
.ot_defaultButton,
.ot_defaultButton.ot_iconButton,
.ot_defaultButton.ot_iconButton:active,
.ot_defaultButton.ot_iconButton.active,
.ot_defaultButton.ot_iconButton.selected { // black and white
  @extend %button_font_size;
  @extend %button_layout;
  background-color: transparent !important;
  color: $gray-dark !important;
  font-weight: 500;
  .ot_svg_fill {
    fill: $gray-dark;
  }
}

.ot_defaultButton {
    // background-color: transparent !important;
    // color: $gray-dark !important;
    border-color: $gray-mid-light;
    // font-weight: 500;

    &:hover, &:focus, &:active,
    &.selected, &.active {
      background-color: $gray-dark !important;
      color: $white !important;
      border-color: $gray-dark;
      .ot_svg_fill {
        fill: $white;
      }
    }
		&.inverse {
	    background-color: $gray-dark !important;
	    color: $white !important;
	    border-color: $gray-dark;
			.ot_svg_fill {
				fill: $white;
			}
    }
		&.inverse.bordered {
		  border-color: $white !important;
		}
  }

.ot_defaultButton.ot_iconButton,
.ot_defaultButton.ot_linkButton {
  &:hover, &:focus, &:active,
  &.active, &.selected {
    background-color: transparent !important;
    color: $gray-dark !important;
    border-color: none !important;
    .ot_svg_fill {
      fill: $gray-dark;
    }
  }
}

    // inverse...
    .ot_defaultButton.inverse {
      background-color: $gray-dark !important;
      color: $white !important;
      border-color: $gray-dark;
      .ot_svg_fill {
        fill: $white;
      }
      &:hover, &:focus, &:active,
      &.active, &.selected  {
        background-color: $white !important;
        color: $gray-dark !important;
        border-color: $gray-mid-light;
        .ot_svg_fill {
  				fill: $gray-dark;
  			}
      }
    }

      .ot_defaultButton.inverse.bordered {
        border-color: $white !important;
      }

      .ot_defaultButton.ot_linkButton.inverse,
      .ot_defaultButton.ot_iconButton.inverse {
        &:hover,
        &:focus {
          background-color: transparent !important;
          color: $white !important;
          border-color: none !important;
          .ot_svg_fill {
    				fill: $white;
    			}
        }
      }
//

// ADMIN BUTTON
.ot_adminButton {
  @extend %button_font_size;
  @extend %button_layout;
  background-color: $admin-blue; //blue
  color: $white; //white
  border-color: $admin-blue;
  font-weight: $fw-bold;
  text-transform: uppercase;
  .ot_svg_fill {
    fill: $admin-blue ;
  }
  &:hover, &:focus, &:active,
  &.active {
    background-color: $admin-blue;
    color: $white;
    border-color: $admin-blue;
    .ot_svg_fill {
      fill: $admin-blue ;
    }
  }
}

.ot_adminButton:disabled,
.ot_adminButton.isDisabled {
  cursor: default;
  opacity: 0.3;
  background-color: $admin-blue;
  border-color: $admin-blue;
  &:hover {
    background-color: $admin-blue;
    border-color: $admin-blue;
  }
}
//

// CANCEL BUTTON
//

// DELETE BUTTON
  .ot_deleteButton {
    @extend %button_font_size;
    @extend %button_layout;
    background-color: transparent !important;
    color: $red !important;
    border-color: $red !important;
    .ot_svg_fill {
      fill: $red;
    }
    &:hover, &:focus {
      background-color: $red !important;
      color: $white !important;
      border-color: $red !important;
      .ot_svg_fill {
        fill: $white;
      }
    }
  }
//

// SUBMIT BUTTON
.ot_successButton {
  @extend %button_font_size;
  @extend %button_layout;
  background-color: $green;
  color: $white;
  border-color: darken($green, 10%);
  .ot_svg_fill {
    fill: $white;
  }
  &:hover, &:focus,
  &:active, &.active {
    background-color: darken($green, 10%);
  }
}
//

// TRANSPARENT BUTTON
.ot_transparentButton {
  @extend %button_custom;
  padding: 6px;
  padding: 6px;
  border: none !important;
  background-color: transparent;
  color: $gray-mid-dark;
  font-size: 12px;
  .ot_svg_fill {
    fill: $gray-mid-dark;
  }
  &:hover, &:focus, &.active {
    background-color: $gray-light;
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out;
  }
}
//

// PRIMARY BUTTON
.ot_primaryButton { // add rule to customization!!!
  @extend %button_font_size;
  @extend %button_layout;
  background-color: $btn-primary-bg; //blue
  color: $btn-primary-color; //white
  border-color: $btn-primary-bg;
  .ot_svg_fill {
    fill: $btn-primary-color ;
  }
  &:hover, &:focus, &:active,
  &.active {
    background-color: $button-hover-color;
    color: $btn-primary-color;
    border-color: $button-hover-color;
    .ot_svg_fill {
      fill: $btn-primary-color ;
    }
  }
}

.ot_primaryPromoButton {
  /* layout */
  width: 71px;
  height: 40px;
  flex-shrink: 0;


  /* typography */
  color: var(--on-surface-medium-emphasis, rgba(0, 0, 0, 0.60));
  font-family: Inter !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  .ot_svg_fill {
    fill: var(--on-surface-medium-emphasis, rgba(0, 0, 0, 0.60));
  }

  &:hover,
  &:focus,
  &:active,
  &.active {
    /* You can adjust hover, focus, and active styles here if needed */
  }
}

.ot_primaryButton:disabled,
.ot_primaryButton.isDisabled {
  cursor: default;
  background-color: $btn-link-disabled-color;
  border-color: $btn-link-disabled-color;
  &:hover {
    background-color: $btn-link-disabled-color;
    border-color: $btn-link-disabled-color;
  }
}
//

// SECONDARY BUTTON
.ot_secondaryButton {
  @extend %button_font_size;
  @extend %button_layout;
  background-color: transparent;
  color: $btn-primary-bg;
  border-color: $btn-primary-bg;
  .ot_svg_fill {
    fill: $btn-primary-bg;
  }
  &:hover, &:active,
  &.active {
    background-color: $btn-primary-bg;
    color: $btn-primary-color;
    border-color: $btn-primary-bg;
    .ot_svg_fill {
      fill: $btn-primary-color;
    }
  }
}
//

// OPTION BUTTON
.ot_optionButton {
  flex-direction: column;
  @include flexCentered();
  height: 90px;
  color: $gray-dark;
  background-color: $white;
  border: 1px solid $gray-mid-light;
  .ot_svg_fill {
    fill: $gray-dark;
  }
  // iPhone 5 only
  @media (min-device-width: 320px)
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {
    height: 80px;
  }
  > * {
    width: inherit; // for accuracy: add width in px
    padding: 0px 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  h6 {
    margin: 0px;
  }
  > p {
    // mobile
    @media (max-width: $screen-xs-max) {
      font-size: 12px;
    }
    // desktop
    @media (min-width: $screen-sm-min) {
      font-size: 14px;
    }
    font-weight: normal;
  }

  > *:first-child {
    padding-bottom: 3px;
    margin: auto auto 0px !important;
    // IE9 + IE10
    @media screen and (min-width:0\0) {
      margin-top: 0px !important;
    }
  }
  > *:last-child {
    margin: 0px auto auto !important;
    // IE9 + IE10
    @media screen and (min-width:0\0) {
      margin-bottom: 0px !important;
    }
  }
  &.active,
  &:hover, &:active {
    border-color: $btn-primary-bg;
    border-width: 2px !important;
    box-shadow: none !important;
  }
}
//

// LINK BUTTON
  .ot_linkButton {
    @extend %button_font_size;
    width: auto !important;
    height: auto !important;
    min-height: 0px;
    padding: 0px;
    border: none !important;
    background-color: transparent !important;
    color: $btn-primary-bg;
    font-weight: normal;
    outline: none;
    &:not(.clean) {
      text-decoration: underline !important;
    }
    .ot_svg_fill {
      fill: $btn-primary-bg;
    }
    &:hover,
    &:active,
    &.active {
      background-color: transparent !important;
      color: $button-hover-color !important;
      border: none !important;
      &:not(.clean) {
        text-decoration: underline !important;
      }
      .ot_svg_fill {
        fill: $button-hover-color;
      }
    }
  }

  .ot_linkButton.inverse,
  .ot_linkButton.inverse:hover,
  .ot_linkButton.inverse:focus,
  .ot_linkButton.inverse:active,
  .ot_linkButton.inverse.active {
    color: $white !important;
    .ot_svg_fill {
      fill: $white;
    }
    &:after {
      color: $white !important;
      .ot_svg_fill {
        fill: $white;
      }
      &:after {
        color: $white !important;
      }
    }
  }
//

// CUSTOM BUTTON
.ot_customButton {
  @extend %button_custom;
  color: $gray-mid-dark;
  padding: 6px;
  border: 1px solid currentColor !important;
  border-radius: 4px;
  background-color: $white;
  font-size: 11px;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
  .ot_svg_fill {
    fill: currentColor;
  }
  &:hover, &:focus, &.active {
    //background-color: currentColor;
    //border: 1px solid currentColor !important;
    color: $white;
  }
}
//

// NAVBAR BUTTON
.ot_navbarButton {
  @extend %button_font_size;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  margin: 0px;
  background-color: transparent;
  color: $navbar-inverse-link-color;
  .ot_svg_fill {
    fill: $navbar-inverse-link-color;
  }
  // mobile
  @media (max-width: $screen-xs-max) {
    height: 40px;
  }
  // desktop
  @media (min-width: $screen-sm-min) {
    height: 100%;
    padding: 0px 20px;
    border-radius: 0px !important;
    border-bottom: none !important;
    &:hover, &:active, &.active {
      border-bottom-color:  $navbar-inverse-link-color !important;
      transition: all 0.8s ease-in-out;
    }
  }
  // mozilla only
  @-moz-document url-prefix() {
    min-width: 100px;
    max-width: 250px;
  }
}
//

// SECTION BUTTON
.ot_sectionButton {
  @extend %button_font_size;
  @extend %button_layout;
  padding: 10px;
  font-size: 12px;
  text-align: left;
  transition: all 0.3s ease-in-out;
  &:hover, &:active,
  &.active, &.selected {
    border-color: $admin-blue;
    box-shadow: 0 2px 8px 0 rgba(136,136,136,0.5);
  }
  &.unselected {
    border-color: transparent;
    background-color: $white; //blue
    color: $gray-dark !important; //white
    box-shadow: 0 2px 8px 0 rgba(136,136,136,0.5) !important;
  }
  &:disabled, &.disabled,
  &.isDisabled {
    cursor: default;
    opacity: 0.8;
    background-color: $gray-light;
  }
}


//

// MENU BUTTON
  .ot_menuButton {
    @extend %button_font_size;
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    border: none;
    width: 200px;
    height: 40px;
    margin: 0px;
    .ot_svg_fill {
      fill: $navbar-inverse-link-color;
    }
    &:hover, &:focus &:active, &.active  {
      padding-right: 3px !important;
    }
    // mobile
    @media (max-width: $screen-xs-max) {
      height: 40px;
      max-width: 60vw;
      background-color: $white;
      color: $gray-dark;
      padding: 0px 20px;
      .ot_svg_fill {
        fill: $gray-dark;
      }
    }
    // desktop
    @media (min-width: $screen-sm-min) {
      padding: 0px 12px;
      border-radius: 0px !important;
      background-color: transparent;
      color: $navbar-inverse-link-color;
    }
  }
//




// ...helpers
@mixin squareButton($size: 40px) {
  width: $size !important;
  height: $size !important;
  flex: 0 0 $size !important;
}

.ot_ci_squareBtn {
  @include squareButton();
}

.ot_ci_squareBtn {
  width: 40px !important;
  height: 40px !important;
  flex: 0 0 40px !important;
}
.ot_ci_underlineLink {
  font-weight: normal;
  text-decoration: underline;
  padding-left: 0px;
}
.ot_ci_underlineLink:hover,
.ot_ci_underlineLink:focus {
  text-decoration: underline;
}
.ot_button.ot_ci_cornerBtn {
  position: absolute;
  top: 5px;
  right: 5px;
}
.ot_btnInline {
  display: inline-block;
}
.ot_spinnerButton {
  position: relative;
}

.ot_ci_dynamic_label {
  &:after {
    content: '';
    display: inline-block;
    width: 10px;
    text-align: left;
  }
  &.dot1:after {
    content: '.';
  }
  &.dot2:after {
    content: '..';
  }
  &.dot3:after {
    content: '...';
  }
 
}

// these rules are needed b/c all button corners' radius is set to 0
//  with the bootstrap override variable
.btn-group {
	.ot_button.ot_primaryButton:first-child {
    border-bottom-left-radius: $border-radius;
    border-top-left-radius: $border-radius;
  }
	.ot_button.ot_primaryButton:last-child {
    border-bottom-right-radius: $border-radius;
    border-top-right-radius: $border-radius;
	}
	.ot_button:active, .ot_button.active {
    -webkit-box-shadow: none;
    box-shadow: none;
	}
  .ot_primaryButton.active:not(.active){
    background-color: rgba($button-color,$button-active-opacity) !important;
    border-color: rgba($button-color,$button-active-opacity) !important;
    color: $btn-primary-color !important;
    font-weight:normal;
    .ot_svg_fill {
      fill: $btn-primary-color;
    }
    &:hover {
      background-color: rgba($button-color,$button-active-opacity) !important;
      border-color: rgba($button-color,$button-active-opacity) !important;
      color: $btn-primary-color !important;
      .ot_svg_fill {
        fill: $btn-primary-color;
      }
    }
  }
}

// this rule is specifically for buttons that are using the i18n
//  component for their label because it adds a span, which
//  blocks the real button value attribute from being tranferred
.ot_button span,
.make_i18n_span_unclickable span {
	pointer-events: none;
}
