@import "~sass/colors";

// TRANSITION
@mixin transition($time: 500ms, $prop: all) {
  -webkit-transition: $prop $time cubic-bezier(0.380, 0.035, 0.455, 0.940);
     -moz-transition: $prop $time cubic-bezier(0.380, 0.035, 0.455, 0.940);
       -o-transition: $prop $time cubic-bezier(0.380, 0.035, 0.455, 0.940);
          transition: $prop $time cubic-bezier(0.380, 0.035, 0.455, 0.940);

  -webkit-transition-timing-function: cubic-bezier(0.380, 0.035, 0.455, 0.940);
     -moz-transition-timing-function: cubic-bezier(0.380, 0.035, 0.455, 0.940);
       -o-transition-timing-function: cubic-bezier(0.380, 0.035, 0.455, 0.940);
          transition-timing-function: cubic-bezier(0.380, 0.035, 0.455, 0.940);
}

@mixin animationTiming() {
  -webkit-animation-timing-function: cubic-bezier(0.380, 0.035, 0.455, 0.940);
     -moz-animation-timing-function: cubic-bezier(0.380, 0.035, 0.455, 0.940);
       -o-animation-timing-function: cubic-bezier(0.380, 0.035, 0.455, 0.940);
          animation-timing-function: cubic-bezier(0.380, 0.035, 0.455, 0.940);
}
// FLEXBOX ...
  @mixin flexCentered() {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   justify-content: center;
  }

// backgrounds...
@mixin bgdGrad() {
  background:
    -webkit-linear-gradient(
        rgba(255,255,255,0) 0%,
        rgba(255,255,255,0.7) 70%,
        rgba(255,255,255,1) 100%);
  background:
    -o-linear-gradient(
        rgba(255,255,255,0) 0%,
        rgba(255,255,255,0.7) 70%,
        rgba(255,255,255,1) 100%);
  background:
    linear-gradient(
        rgba(255,255,255,0) 0%,
        rgba(255,255,255,0.7) 70%,
        rgba(255,255,255,1) 100%);
}

@mixin topBgdGrad($r: 255, $g: 255, $b: 255) {
  background: -moz-linear-gradient(
      top,
      rgba($r, $g, $b, 1) 0%,
      rgba($r, $g, $b,0) 100%);
  background: -webkit-linear-gradient(
      top,
      rgba($r, $g, $b, 1) 0%,
      rgba($r, $g, $b,0) 100%);
  background: linear-gradient(
      to bottom,
    rgba($r, $g, $b, 1) 0%,
    rgba($r, $g, $b,0) 100%);
}

@mixin bottomBgdGrad($r: 255, $g: 255, $b: 255) {
  background: -moz-linear-gradient(
      top,
      rgba($r, $g, $b, 0) 0%,
      rgba($r, $g, $b,1) 99%);
  background: -webkit-linear-gradient(
      top,
      rgba($r, $g, $b, 0) 0%,
      rgba($r, $g, $b,1) 99%);
  background: linear-gradient(
      to bottom,
      rgba($r, $g, $b, 0) 0%,
      rgba($r, $g, $b,1) 99%);
}
@mixin leftBgdGrad($r: 255, $g: 255, $b: 255) {
  background: -moz-linear-gradient(
      left,
      rgba($r, $g, $b, 1) 0%,
      rgba(255,255,255, 0.7) 50%,
      rgba($r, $g, $b, 0) 100%);
  background: -webkit-linear-gradient(
      left,
      rgba($r, $g, $b, 1) 0%,
      rgba(255,255,255, 0.7) 50%,
      rgba($r, $g, $b,0) 100%);
  background: linear-gradient(
      to right,
      rgba($r, $g, $b, 1) 0%,
      rgba(255,255,255, 0.7) 50%,
      rgba($r, $g, $b, 0) 100%);
}

@mixin rightBgdGrad($r: 255, $g: 255, $b: 255) {
  background: -moz-linear-gradient(
      left,
      rgba($r, $g, $b, 0) 0%,
      rgba(255,255,255, 0.7) 50%,
      rgba($r, $g, $b, 1) 100%);
  background: -webkit-linear-gradient(
      left,
      rgba($r, $g, $b, 0) 0%,
      rgba(255,255,255, 0.7) 50%,
      rgba($r, $g, $b,1) 100% );
  background: linear-gradient(
      to right,
      rgba($r, $g, $b, 0) 0%,
      rgba(255,255,255, 0.7) 50%,
      rgba($r, $g, $b,1) 100% );
}

// rules...
@mixin ruleTop($pb: 5px, $mb: 10px) {
  padding-top: $pb;
  border-top: 1px solid $gray-rule;
  margin-top: $mb;
}
@mixin ruleRight($pb: 5px, $mb: 10px) {
  padding-right: $pb;
  border-right: 1px solid $gray-rule;
  margin-right: $mb;
}
@mixin ruleBtm($pb: 5px, $mb: 10px) {
  padding-bottom: $pb;
  border-bottom: 1px solid $gray-rule;
  margin-bottom: $mb;
}
@mixin ruleLeft($pb: 5px, $mb: 10px) {
  padding-left: $pb;
  border-left: 1px solid $gray-rule;
  margin-left: $mb;
}

// icons
@mixin icon-circle($size) {
   display: flex;
   justify-content: center;
   align-items: center;
    width: $size+1;
    height: $size;
    border-radius: 50%;
    i {
      text-align: center;
    }
}

.ot_hasIcon {
 display: flex;
 justify-content: flex-start;
 align-items: center;
}

@mixin iconBefore($url) {
  @extend .ot_hasIcon;
  &:before {
    content: '';
   flex-shrink: 0;
    display: block;
    width: 24px;
    height: 24px;
    margin-right: 5px;
    background-repeat: no-repeat;
    background-position: left center;
    background-image: url($url) !important;
  }
}

@mixin iconAfter($url) {
  @extend .ot_hasIcon;
  &:after {
    content: '';
   flex-shrink: 0;
    display: block;
    width: 24px;
    height: 24px;
    margin-left: 5px;
    background-repeat: no-repeat;
    background-position: right center;
    background-image: url($url) !important;
  }
}
