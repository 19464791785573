@import '~sass/colors';
@import '~sass/variables';
@import "~sass/fonts";
@import url('https://fonts.googleapis.com/css?family=Inter:400,600,700&display=swap');

%formElement {
   width: 100%;
   height: 40px;
   padding-left: 12px;
   padding-right: 12px;
   border: 1px solid $gray-mid-light;
   border-radius: 3px;
   color: $font-color;
   background-color: $white;
   box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
   outline: none;
   font-size: 14px;
   transition: all 0.3s ease-in-out;
   -webkit-appearance: none;
   -moz-appearance: none;
   -ms-appearance: none;
   -o-appearance: none;
   appearance: none;
   &.success {
     border-color: $success-color;
   }
   &.invalid {
     border-color: $error-color;
   }
   > * {
	 pointer-events: none;
	}
}

input.disabled, input:disabled,
textarea.disabled, textarea:disabled,
select.disabled, select:disabled  {
   cursor: not-allowed !important;
   opacity: 0.5 !important;
   background-color: $gray-light !important;
 }

 %textElement {
  padding-left: 12px;
  padding-right: 12px;

 }

button > * {
 pointer-events: none;
}

.ot_inputContainer {
   width: 100%;
   min-height: 40px;
   margin-left: 0px !important;
   input, textarea {
    @extend %formElement;
    @extend %textElement;
    margin-bottom: 5px;
   }
   textarea {
    height: auto;
    resize: none;
   }
   @media (max-width: 850px) {
     input, textarea {
       padding-left: 6px;
       padding-right: 6px;
     }
   }
}

.ot_selectContainer {
  position: relative;
  width: 100%;
  background-color: #fff;
  min-height: 65px;
  &:after {
   content: '';
   position: absolute;
   right: 6px;
   top: 17px;
   z-index: 0;
   width: 10px;
   height: 5px;
   background: url("../../../images/icons/caret.svg") no-repeat center center;
  }
  select {
    position: relative;
    z-index: 5;
    background-color: transparent;
    margin-bottom: 5px;
    @extend %formElement;
    padding-right: 0px;
    &::-ms-expand {
      // REMOVES NATIVE DROPDOWN ON IE
      display: none;
    }
    option {
      padding-right: 15px;
    }
  }
}

.ot_selectContainer.ot_form-small {
  &:after {
    top: 13px;
  }
  select {
    height: 30px;
    padding-left: 3px;
    // padding-right: 12px;
    font-size: 12px !important;
  }
}

.ot_error__container {
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  &.close {
    max-height: 0px;
    opacity: 0;
  }
  &.open {
    max-height: none;
    opacity: 1;
  }
}

.ot_error_label {
  line-height: 1.4em;
  font-size: 11px;
  display: block;
  color: $error-color;
}

label {
	font-weight: normal;
}

.ot_mandatoryAsterisks {
  color: $error-color;
}

.ot_inputButton {
  position: relative;
  width: 100%;
  min-height: 40px;
  margin-bottom: 20px;
  .input_field {
    padding-right: 0px !important;
  }
  .submit_button {
    position: absolute;
    top: 5px;
    right: 6px;
    z-index: 10;
    width: 50px;
    height: 30px;
  }
  .ot_ci_error {
    position: absolute;
    bottom: -0px;
    left: 0px;
    color: $red;
    font-size: 11px;
  }
}

.ot_flex_noPadding{
  padding:0px !important;
}
.ot_inputPromoButton {
  position: relative;
  width: 100% !important;
  display: flex;
  align-items: stretch;
  margin-bottom: 20px;
  @media (max-width: 982px) {
    margin-bottom: 40px;
  }

  .input_field {
    flex-grow: 1;
    padding-right: 0;
  }

    /* Style for the Apply button */
  .submit_button {
    /* Normal state styles */
    background: #D9D9D9; /* Set the desired background color */
    border: none; /* Remove the border */
    border-radius: 0px 4px 4px 0px;
    color: var(--on-surface-medium-emphasis, rgba(0, 0, 0, 0.60));
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer; /* Add this to change the cursor on hover */

    /* Hover state styles (remove the blue hover effect) */
    &:hover {
      background: #D9D9D9; /* Set the same background color for hover state */
    }
  }

  .ot_ci_error {
    color: $red;
    font-size: 11px;
    position: absolute;
    bottom: -20px;
    left: 0;
    @media (max-width: 982px) {
      bottom: -20px;
    }
  }
  .ot_ci_error_mobile {
    color: $red;
    font-size: 11px;
    position: absolute;
    bottom: -20px;
    left: 0;
  }
  .ot_ci_correct {
    color: $green;
    font-size: 11px;
    position: absolute;
    bottom: -20px;
    left: 0;
    @media (max-width: 982px) {
      bottom: -20px;
    }
  }

}