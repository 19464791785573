/* fonts.scss */
@import "~sass/media";
@import "~sass/variables";
@import "~sass/common/mixins/layout";
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');


/* ===================================== */
/* =========  font-colors  ============== */
/* ===================================== */
.ot_ci_success {
  color: $green !important;
}

/* ===================================== */
/* =========  font space  ============== */
/* ===================================== */
$spaced-out: 0.04em;


/* ===================================== */
/* ======  font weights      =========== */
/* ===================================== */
$fw-light: 300;
$fw-regular: 400;
$fw-semi-bold: 500;
$fw-bold: 600;

.ot_ci_fw_bold {
  font-weight: $fw-bold !important; //600
}

.ot_ci_fw_lite {
  font-weight: $fw-light !important; //300
}

.ot_ci_fw_normal {
  font-weight: $fw-regular !important; //400
}

.ot_ci_fw_semi_bold {
  font-weight: $fw-semi-bold !important; //500
}

/* ===================================== */
/* ======  font sizes     =========== */
/* ===================================== */
$fz-xsm: 0.75rem; // 10px
$fz-sm: 0.85rem; // 12px
$fz-regular: 14px; // body, html
$fz-md: 1.15rem; // 16px
$fz-lg: 1.4rem; // 20px
$fz-lg-plus: 1.75rem; // 24px
$fz-xl: 2.15rem; // 30px
$font-adjust: 0.45; // for small fonts on firefox

.ot_ci_fz_xsm {
  font-size: $fz-xsm !important; // 10
}

.ot_ci_text_xs {
  // mobile and tablet portrait
  @media (max-width: $screen-sm-max) {
    font-size: $fz-xsm !important; // 10
  }
  // desktop
  @media (min-width: $screen-md-min) {
    font-size: $fz-sm !important;// 12
  }
}

.ot_ci_fz_sm {
  font-size: $fz-sm !important; // 12
}

.ot_ci_text_small {
  // mobile and tablet portrait
  @media (max-width: $screen-sm-max) {
    font-size: $fz-sm; // 12px
  }
  // desktop
  @media (min-width: $screen-md-min) {
    font-size: $fz-regular; // 14px
  }
}

.ot_ci_fz_regular {
  font-size: $fz-regular !important; // 14
}

.ot_ci_fz_md {
  font-size: $fz-md !important; // 16
}

.ot_ci_fz_lg {
  font-size: $fz-lg !important; // 20
}

.ot_ci_fz_lg_plus {
  font-size: $fz-lg-plus !important; // 24
}

.ot_ci_fz_xl {
   font-size: $fz-xl !important; // 30
}



/* ===================================== */
/* =============  headers ============== */
/* ===================================== */

/* h1 */
h1, .ot_ci_heading1 {
  font-weight: $fw-light;
  // mobile
  @media (max-width: $screen-xs-max) {
    font-size: $fz-lg; // 20px
    line-height: 1.1em;
  }
  // tablet portrait
  @media (min-width: $screen-sm-min) and
  (max-width: $screen-sm-max){
    font-size: $fz-lg-plus; //24px
    line-height: 1.2em;
  }
  // desktop
  @media (min-width: $screen-md-min) {
    font-size: $fz-xl; //30px
    line-height: 1.2em;
  }
}

/* h2 & h3 */

h2, .ot_ci_heading2 {
  font-weight: $fw-bold;
  // mobile
  @media (max-width: $screen-xs-max) {
    font-size: $fz-lg; //20px
    line-height: 1.1em;
  }
  // tablet portrait
  @media (min-width: $screen-sm-min) and
  (max-width: $screen-sm-max){
    font-size: $fz-lg; // 20px
    line-height: 1.1em;
  }
  // desktop
  @media (min-width: $screen-md-min) {
    font-size: $fz-lg-plus; //24px
    line-height: 1.2em;
  }
}

h3, .ot_ci_heading3 {
  font-size: 1.28rem; // 18px
  font-weight: $fw-regular;
  line-height: 1.2em;
}



/* h4 */
h4, .ot_ci_heading4 {
  font-size: $fz-md; //16px
  line-height: 1.2em;
}

/* h5 & h6 */
h5, .ot_ci_heading5,
h6, .ot_ci_heading6 {
  letter-spacing: $spaced-out;
  line-height: 1.6em;
  color: $gray-mid-dark;
}

h5,
.ot_ci_heading5 {
  //font-weight: $fw-bold;
  // mobile and tablet portrait
  @media (max-width: $screen-sm-max) {
    font-size: $fz-sm; //12px
  }
  // desktop
  @media (min-width: $screen-md-min) {
    font-size: $fz-regular; //14px
  }
}

h6,
.ot_ci_heading6 {
   //12px
  // mobile and tablet portrait
  @media (max-width: $screen-sm-max) {
    font-size: $fz-xsm;
    //font-weight: $fw-semi-bold !important;
    // for mozilla
    font-size-adjust: $font-adjust;
  }
  // desktop
  @media (min-width: $screen-md-min) {
    font-size: $fz-sm;
  }
}


/* ===================================== */
/* ======  classes  & helpers =========== */
/* ===================================== */

/* alignment*/
.ot_ci_text_left {
  text-align: left !important;
}

/* classes*/
.ot_ci_error {
  font-weight: normal !important;
  font-size: $fz-sm !important; //12
  color: $red !important;
  line-height: 1.4em;
}

.ot_ci_lite {
  letter-spacing: 0.04em;
  // mobile and tablet portrait
  @media (max-width: $screen-sm-max) {
    font-size: $fz-sm; //12px
  }
  // desktop
  @media (min-width: $screen-md-min) {
    font-size: $fz-regular; //14px
    font-weight: $fw-light;
  }
}

.ot_ci_subtitle {
    // mobile and tablet portrait
    @media (max-width: $screen-sm-max) {
      font-size: $fz-sm; //12px
    }
    // desktop
    @media (min-width: $screen-md-min) {
      font-size: $fz-regular; //14px
    }
}

.ot_ci_subtitleSm {
  font-weight: $fw-bold;
  letter-spacing: $spaced-out;
  line-height: 1.6em;
  color: $gray-mid-dark;
  // mobile and tablet portrait
  @media (max-width: $screen-sm-max) {
    font-size: $fz-xsm;
    font-size-adjust: $font-adjust;
  }
  // desktop
  @media (min-width: $screen-md-min) {
    font-size: $fz-sm; //12px
  }
}

.ot_ci_systemMessage {
  margin: 60px auto;
  text-align: center;
  text-transform: capitalize;
}

.ot_ci_tag  {
  font-weight: normal !important;
  font-size: $fz-sm !important;//12
  letter-spacing: normal !important;
}

  .ot_ci_tagBgd {
    @extend .ot_ci_tag;
    // centered text over gray background
    display: block;
    width: 100%;
    padding: 1px 3px;
    border-radius: $border-radius;
    background-color: $gray-light;
    text-align: center;
  }

/* text styles */
.ot_ci_capitalize {
  text-transform: capitalize;
}

.ot_ci_capstitle {
  font-weight: $fw-bold;
  letter-spacing: $spaced-out;
  text-transform: uppercase;
}

.ot_ci_italic {
  font-style: italic;
}

.ot_ci_ruled {
  @include ruleBtm(5px, 20px);
}

.ot_ci_ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

%ot_ci_cursor {
  &:after {
    content: '';
    display: block;
    width: 30px;
    padding-bottom: 3px;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: inherit;
    --webkit-transition: all 0.6s ease-in-out;
    transition: all 0.6s ease-in-out;

  }
  &:hover:after {
    width: 100%;
    --webkit-transition: all 0.6s ease-in-out;
    transition: all 0.6s ease-in-out;
  }
}