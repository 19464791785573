@import "~sass/common/styles/animations";

@-webkit-keyframes pulseRing {
  0% {
    -webkit-transform: scale(.625);
  }
  100% {
    opacity: 0;
  }
}

@keyframes pulseRing {
  0% {
    -webkit-transform: scale(.625);
  }
  100% {
    opacity: 0;
  }
}



.ot_ringIcon {
  &.static .ring {
      opacity: 0.5;
      transform: scale(0.6);
      transform-origin: 50%;
      //-webkit-transform: scale(.625);
  }
  &.pulsate:not(.anime_fadeIn) .ring {
    transform-origin: 50%;
    //-webkit-animation: pulseRing 1.6s 0s infinite
    //  cubic-bezier(0.215, 0.61, 0.355, 1);
    animation: pulseRing 1.6s 0s infinite
      cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  &.anime_fadeIn {
    .ring {

    opacity: 1;
    //-webkit-transform-origin: 50%;
    transform-origin: 50%;
    //-webkit-transform: scale(0.8);
    transform: scale(0.75);
    animation-delay: 0.3s;}
  }
}


//@mixin bounce() {
//  @include animationTiming();
//  animation-name: bounce;
//  -webkit-animation-name: bounce;
//  animation-iteration-count: 1;
//  -webkit-animation-iteration-count: 1;
//  animation-duration: 1.6s;
//  -webkit-animation-duration: 1.6s;
//  transform-origin: 50% 100%;
//  -ms-transform-origin: 50% 100%;
//  -webkit-transform-origin: 50% 100%;
//}